/**
 * Type: ページ
 * What: 団体予約フォームページ
 */
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import { Button } from '../../components/Btn';

const pageTitle = '団体お申込み受付完了';
const pageSlug = 'inquiry';

// Data Props Template
const groupPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="title is-3">{pageTitle}</h3>
        <div className="inner-slim form-cts">
          <p>
            お送りいただきました内容を確認の上、担当者より折り返しご連絡させていただきます。
            <br />
            メール受信から3営業日以内に返信いたします。
          </p>
          <p>
            また、ご記入いただきましたメールアドレスへ、自動返信の確認メールを送付しております。
            <br />
            自動返信メールが届かない場合、迷惑メール用の受信ボックスに届いているか、入力いただいたメールアドレスに誤りがあった可能性がございます。
          </p>
          <p>
            受信設定とメールアドレスをご確認のうえ、再度フォームよりお問い合わせいただきますようお願い申し上げます。
          </p>
        </div>
        <div className="btn-wrap mt-6">
          <Button href="/">TOPへ戻る</Button>
        </div>
      </div>
    </section>
  </Layout>
);

export default groupPage;
